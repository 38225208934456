import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import axios, { AxiosError } from "axios";

import { auth, firestore } from "./config";
import { CurrentUserType } from "../redux/user/user.types";
import { PHVehicleType, VehicleType } from "../redux/vehicles/vehicles.types";

// ---===USERS===---
// All Users
//get User by ID
export const getUserById = async (userId: string) => {
  const userDocRef = doc(firestore, "users", userId);
  try {
    const userSnapShot = await getDoc(userDocRef);
    const fetchedUser = {
      id: userSnapShot.id,
      ...userSnapShot.data(),
      createdAt: userSnapShot.data()!.createdAt.toMillis(),
      lastUpdate: userSnapShot.data()!.lastUpdate.toMillis(),
    };
    return fetchedUser as CurrentUserType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error fetching User by ID");
    }
  }
};
// Users - Drivers
//get list of drivers waiting for paperwork approval
export const getDriversForApproval = async () => {
  const userColRef = collection(firestore, "users");
  const userQuery = query(
    userColRef,
    where("waitingDriverApproval", "==", true),
    limit(50)
  );
  try {
    const querySnapshot = await getDocs(userQuery);
    const userList = querySnapshot.docs.map((docSnapshot) => {
      //transforming timestamps from non-serializable to milliseconds number
      return {
        ...docSnapshot.data(),
        id: docSnapshot.id,
        createdAt: docSnapshot.data()!.createdAt.toMillis(),
        lastUpdate: docSnapshot.data()!.lastUpdate.toMillis(),
      };
    });
    return userList as CurrentUserType[];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error fetching Users for approval");
    }
  }
};
//approve paperwork for User Driver
export const approveUserDriverDoc = async (
  userId: string,
  docType: string,
  expiryDate: number
) => {
  const userDocRef = doc(firestore, "users", userId);
  try {
    //get data that we have stored already
    const oldValueSnap = await getDoc(userDocRef);
    const oldValue = oldValueSnap.data();
    if (
      !oldValue ||
      !oldValue.driversData ||
      !oldValue.driversData[docType] ||
      !oldValue.driversData[docType].pendingUrl
    ) {
      throw new Error("No pendingUrl for this documet!");
    }
    //set fields individually, if we have previous doc URL and expiry date, set them as "expiring"
    if (
      oldValue.driversData[docType].downloadUrl &&
      oldValue.driversData[docType].expires
    ) {
      await updateDoc(userDocRef, {
        [`driversData.${docType}.expires`]: expiryDate,
        [`driversData.${docType}.expiresSoon`]: false, //needed for expiry check cron job
        [`driversData.${docType}.pendingUrl`]: "",
        [`driversData.${docType}.downloadUrl`]:
          oldValue.driversData[docType].pendingUrl,
        [`driversData.${docType}.reasonOfDecline`]: "",
        [`driversData.${docType}.expiringUrl`]:
          oldValue.driversData[docType].downloadUrl,
        [`driversData.${docType}.expiringExpires`]:
          oldValue.driversData[docType].expires,
        [`driversData.${docType}.approved`]: true,
        lastUpdate: serverTimestamp(),
      });
    } else {
      await updateDoc(userDocRef, {
        [`driversData.${docType}.expires`]: expiryDate,
        [`driversData.${docType}.expiresSoon`]: false, //needed for expiry check cron job
        [`driversData.${docType}.pendingUrl`]: "",
        [`driversData.${docType}.downloadUrl`]:
          oldValue.driversData[docType].pendingUrl,
        [`driversData.${docType}.reasonOfDecline`]: "",
        [`driversData.${docType}.approved`]: true,
        lastUpdate: serverTimestamp(),
      });
    }

    const updatedUserSnapShot = await getDoc(userDocRef);
    const fetchedUser = {
      id: updatedUserSnapShot.id,
      ...updatedUserSnapShot.data(),
      createdAt: updatedUserSnapShot.data()!.createdAt.toMillis(),
      // lastUpdate: updatedUserSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedUser as CurrentUserType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error approving a document for the Driver!");
    }
  }
};
//reject paperwork for User Driver
export const rejectUserDriverDoc = async (
  userId: string,
  docType: string,
  reasonOfDecline: string
) => {
  const userDocRef = doc(firestore, "users", userId);
  try {
    //updateDoc will replace data in nested destination
    //setDoc with {merge: true} will affect only specified fields, doesn't work with nested though..
    //set fields individually to keep expiry date if getting updated
    await updateDoc(userDocRef, {
      [`driversData.${docType}.pendingUrl`]: "",
      [`driversData.${docType}.reasonOfDecline`]: reasonOfDecline,
      [`driversData.${docType}.approved`]: false,
      lastUpdate: serverTimestamp(),
    });

    const updatedUserSnapShot = await getDoc(userDocRef);
    const fetchedUser = {
      id: updatedUserSnapShot.id,
      ...updatedUserSnapShot.data(),
      createdAt: updatedUserSnapShot.data()!.createdAt.toMillis(),
      // lastUpdate: updatedUserSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedUser as CurrentUserType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error rejecting a document for the Driver!");
    }
  }
};
//Verify DVLA Check code
export const verifyDvlaCheckCode = async (
  userId: string,
  isVerified: boolean
) => {
  const userDocRef = doc(firestore, "users", userId);

  try {
    //set fields individually
    await updateDoc(userDocRef, {
      "driversData.DVLACheckCode.approved": isVerified,
      "driversData.DVLACheckCode.pending": false,
      lastUpdate: serverTimestamp(),
    });

    const updatedUserSnapShot = await getDoc(userDocRef);
    const fetchedUser = {
      id: updatedUserSnapShot.id,
      ...updatedUserSnapShot.data(),
      createdAt: updatedUserSnapShot.data()!.createdAt.toMillis(),
      // lastUpdate: updatedUserSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedUser as CurrentUserType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error verifying DVLA check code for the Driver!");
    }
  }
};
//Set numbers of DVLA licence and Badge
export const inputLicenceNumbers = async (
  userId: string,
  dvlaLicenceNumber: string,
  badgeNumber: string
) => {
  const userDocRef = doc(firestore, "users", userId);
  const approvedDriverDocRef = doc(firestore, "approvedDrivers", userId);

  try {
    //set fields individually
    await updateDoc(userDocRef, {
      "driversData.dvlaLicenceNumber": dvlaLicenceNumber,
      "driversData.badgeNumber": badgeNumber,
      lastUpdate: serverTimestamp(),
    });

    // Check if the approvedDrivers document exists
    const approvedDriverDocSnap = await getDoc(approvedDriverDocRef);
    if (approvedDriverDocSnap.exists()) {
      // Update the badgeNumber field if the document exists
      await updateDoc(approvedDriverDocRef, {
        badgeNumber: badgeNumber,
      });
    }

    const updatedUserSnapShot = await getDoc(userDocRef);
    const fetchedUser = {
      id: updatedUserSnapShot.id,
      ...updatedUserSnapShot.data(),
      createdAt: updatedUserSnapShot.data()!.createdAt.toMillis(),
      // lastUpdate: updatedUserSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedUser as CurrentUserType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error setting licence numbers for the Driver!");
    }
  }
};
//Set Driver's waiting for approval status as false
export const removeDriverApprovalWaiting = async (userId: string) => {
  const userDocRef = doc(firestore, "users", userId);

  try {
    //set fields individually
    await updateDoc(userDocRef, {
      waitingDriverApproval: false,
      lastUpdate: serverTimestamp(),
    });

    const updatedUserSnapShot = await getDoc(userDocRef);
    const fetchedUser = {
      id: updatedUserSnapShot.id,
      ...updatedUserSnapShot.data(),
      createdAt: updatedUserSnapShot.data()!.createdAt.toMillis(),
      // lastUpdate: updatedUserSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedUser as CurrentUserType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error resetting Driver's waiting for approval status!");
    }
  }
};
//Activate or suspend a Driver
export const changeDriverStatus = async (
  userId: string,
  isApproved: boolean
) => {
  //user data
  const userDocRef = doc(firestore, "users", userId);
  //general Drivers data from utilData collection
  const driversDataRef = doc(firestore, "utilData", "driversData");

  try {
    //set fields individually
    await updateDoc(userDocRef, {
      approvedDriver: isApproved,
      lastUpdate: serverTimestamp(),
    });
    const updatedUserSnapShot = await getDoc(userDocRef);
    const fetchedUser = {
      id: updatedUserSnapShot.id,
      ...updatedUserSnapShot.data(),
      createdAt: updatedUserSnapShot.data()!.createdAt.toMillis(),
      // lastUpdate: updatedUserSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    } as CurrentUserType;
    //get general data
    const driversData = await getDoc(driversDataRef);
    if (
      !driversData.exists() ||
      typeof driversData.data().numberCount !== "number" ||
      typeof driversData.data().activeDrivers !== "number"
    ) {
      throw new Error(
        "Can't find driversData or numberCount or activeDrivers in utilData!"
      );
    }
    //insert or remove driver into/from approvedDrivers collection
    const approvedDriverDocRef = doc(firestore, "approvedDrivers", userId);
    if (isApproved) {
      //check if the Driver has driverNumber, if not assign one
      let driverNumber: string;
      if (fetchedUser.driverNumber) {
        driverNumber = fetchedUser.driverNumber;
      } else {
        driverNumber = (driversData.data().numberCount + 1).toString();
        await updateDoc(driversDataRef, {
          numberCount: increment(1),
          lastUpdate: serverTimestamp(),
        });
        await updateDoc(userDocRef, {
          driverNumber,
          lastUpdate: serverTimestamp(),
        });
      }
      //check if Driver's selected vehicle is approved
      const vehiclesRef = collection(firestore, "users", userId, "PHVehicles");
      const selectedVehicleQuery = query(
        vehiclesRef,
        where("selected", "==", true),
        where("approved", "==", true)
      );
      const selectedVehicleSnapshot = await getDocs(selectedVehicleQuery);
      if (!selectedVehicleSnapshot.empty && !fetchedUser.readyToDrive) {
        //selected vehicle is approved - insert data into approved drivers collection
        const selectedVehicle =
          selectedVehicleSnapshot.docs[0].data() as PHVehicleType;
        await setDoc(approvedDriverDocRef, {
          driverNumber,
          name: fetchedUser.name,
          phoneNumber: fetchedUser.phoneNumber,
          licensingAuthority: fetchedUser.driversData?.licensingAuthority,
          badgeNumber: fetchedUser.driversData?.badgeNumber,
          availableVehicleTypes: selectedVehicle.vehicleTypes,
          selectedVehicleTypes: selectedVehicle.vehicleTypes,
          pausedRequests: false,
          petFriendly: fetchedUser.driversData?.petFriendly,
          PHvehicle: {
            numberPlate: selectedVehicle.numberPlate,
            licencePlate: selectedVehicle.licencePlate,
            make: selectedVehicle.make,
            model: selectedVehicle.model,
            color: selectedVehicle.color,
          },
        });
        await updateDoc(userDocRef, {
          readyToDrive: true,
          lastUpdate: serverTimestamp(),
        });
      }
      fetchedUser.driverNumber = driverNumber;
    } else {
      //isApproved is false, so we suspend driver
      const approvedDriverSnapshot = await getDoc(approvedDriverDocRef);
      if (approvedDriverSnapshot.exists()) {
        await deleteDoc(approvedDriverDocRef);
      }
      await updateDoc(userDocRef, {
        readyToDrive: false,
        lastUpdate: serverTimestamp(),
      });
    }
    return fetchedUser;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error changing Driver status!");
    }
  }
};

// ---===VEHICLES===---
//get user vehicles for admin request
export const getUserVehiclesAdmin = async (userId: string) => {
  const vehiclesColRef = collection(firestore, "users", userId, "PHVehicles");
  //limit 15 to see if user managed to exceed limit of 10
  const vehicleQuery = query(vehiclesColRef, limit(15));
  try {
    const querySnapshot = await getDocs(vehicleQuery);
    const vehicleList = querySnapshot.docs.map((docSnapshot) => {
      //transforming timestamps from non-serializable to milliseconds number
      return {
        ...docSnapshot.data(),
        createdAt: docSnapshot.data()!.createdAt.toMillis(),
        lastUpdate: docSnapshot.data()!.lastUpdate.toMillis(),
      };
    });
    return vehicleList as PHVehicleType[];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error fetching PH Vehicles for User");
    }
  }
};
//get User's vehicle by numberPlate
export const getVehicleByNumberPlate = async (
  userId: string,
  numberPlate: string
) => {
  const vehiclesDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );
  try {
    const vehicleSnapshot = await getDoc(vehiclesDocRef);
    const fetchedVehicle = {
      ...vehicleSnapshot.data(),
      createdAt: vehicleSnapshot.data()!.createdAt.toMillis(),
      lastUpdate: vehicleSnapshot.data()!.lastUpdate.toMillis(),
    };
    return fetchedVehicle as PHVehicleType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error fetching PH Vehicle for User");
    }
  }
};
//approve paperwork for the vehicle
export const approveUserVehicleDoc = async (
  userId: string,
  numberPlate: string,
  docType: string,
  expiryDate: number
) => {
  const vehicleDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );
  try {
    //get data that we have stored already
    const oldValueSnap = await getDoc(vehicleDocRef);
    const oldValue = oldValueSnap.data();
    if (!oldValue || !oldValue[docType] || !oldValue[docType].pendingUrl) {
      throw new Error("No pendingUrl for this documet!");
    }
    //set fields individually, if we have previous doc URL and expiry date, set them as "expiring"
    if (oldValue[docType].downloadUrl && oldValue[docType].expires) {
      await updateDoc(vehicleDocRef, {
        [`${docType}.expires`]: expiryDate,
        [`${docType}.expiresSoon`]: false, //needed for expiry check cron job
        [`${docType}.pendingUrl`]: "",
        [`${docType}.downloadUrl`]: oldValue[docType].pendingUrl,
        [`${docType}.reasonOfDecline`]: "",
        [`${docType}.expiringUrl`]: oldValue[docType].downloadUrl,
        [`${docType}.expiringExpires`]: oldValue[docType].expires,
        [`${docType}.approved`]: true,
        lastUpdate: serverTimestamp(),
      });
    } else {
      await updateDoc(vehicleDocRef, {
        [`${docType}.expires`]: expiryDate,
        [`${docType}.expiresSoon`]: false, //needed for expiry check cron job
        [`${docType}.pendingUrl`]: "",
        [`${docType}.downloadUrl`]: oldValue[docType].pendingUrl,
        [`${docType}.reasonOfDecline`]: "",
        [`${docType}.approved`]: true,
        lastUpdate: serverTimestamp(),
      });
    }

    const updatedVehicleSnapShot = await getDoc(vehicleDocRef);
    const fetchedVehicle = {
      ...updatedVehicleSnapShot.data(),
      createdAt: updatedVehicleSnapShot.data()!.createdAt.toMillis(),
      //lastUpdate: updatedVehicleSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedVehicle as PHVehicleType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error approving a document for the vehicle!");
    }
  }
};
//reject paperwork for the vehicle
export const rejectUserVehicleDoc = async (
  userId: string,
  numberPlate: string,
  docType: string,
  reasonOfDecline: string
) => {
  const vehicleDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );
  try {
    //updateDoc will replace data in nested destination
    //setDoc with {merge: true} will affect only specified fields, doesn't work with nested though..
    //set fields individually to keep expiry date if getting updated
    await updateDoc(vehicleDocRef, {
      [`${docType}.pendingUrl`]: "",
      [`${docType}.reasonOfDecline`]: reasonOfDecline,
      [`${docType}.approved`]: false,
      lastUpdate: serverTimestamp(),
    });

    const updatedVehicleSnapShot = await getDoc(vehicleDocRef);
    const fetchedVehicle = {
      ...updatedVehicleSnapShot.data(),
      createdAt: updatedVehicleSnapShot.data()!.createdAt.toMillis(),
      //lastUpdate: updatedVehicleSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedVehicle as PHVehicleType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error rejecting a document for the vehicle!");
    }
  }
};
//set color, make & model and plate number for the vehicle
export const setMakeAndModel = async (
  userId: string,
  numberPlate: string,
  make: string,
  model: string,
  color: string,
  licencePlate: string,
  vehicleTypes: VehicleType[]
) => {
  const vehicleDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );
  const approvedDriverDocRef = doc(firestore, "approvedDrivers", userId);
  try {
    await updateDoc(vehicleDocRef, {
      make,
      model,
      color,
      licencePlate,
      vehicleTypes,
      lastUpdate: serverTimestamp(),
    });

    // Check if the approvedDrivers document exists
    const approvedDriverDocSnap = await getDoc(approvedDriverDocRef);
    if (approvedDriverDocSnap.exists()) {
      // Update the badgeNumber field if the document exists
      await updateDoc(approvedDriverDocRef, {
        availableVehicleTypes: vehicleTypes,
        selectedVehicleTypes: vehicleTypes,
        "PHvehicle.licencePlate": licencePlate,
        "PHvehicle.make": make,
        "PHvehicle.model": model,
        "PHvehicle.color": color,
      });
    }

    const updatedVehicleSnapShot = await getDoc(vehicleDocRef);
    const fetchedVehicle = {
      ...updatedVehicleSnapShot.data(),
      createdAt: updatedVehicleSnapShot.data()!.createdAt.toMillis(),
      //lastUpdate: updatedVehicleSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedVehicle as PHVehicleType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error setting Make & Model for the vehicle!");
    }
  }
};
//Set vehicles's waiting for approval status as false
export const removeVehicleApprovalWaiting = async (
  userId: string,
  numberPlate: string
) => {
  const vehicleDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );

  try {
    //set fields individually
    await updateDoc(vehicleDocRef, {
      waitingApproval: false,
      lastUpdate: serverTimestamp(),
    });

    const updatedVehicleSnapShot = await getDoc(vehicleDocRef);
    const fetchedVehicle = {
      ...updatedVehicleSnapShot.data(),
      createdAt: updatedVehicleSnapShot.data()!.createdAt.toMillis(),
      //lastUpdate: updatedVehicleSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    };
    return fetchedVehicle as PHVehicleType;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error resetting vehicle's waiting for approval status!");
    }
  }
};
//Activate or suspend a vehicle
export const changeVehicleStatus = async (
  userId: string,
  numberPlate: string,
  isApproved: boolean
) => {
  const vehicleDocRef = doc(
    firestore,
    "users",
    userId,
    "PHVehicles",
    numberPlate
  );
  try {
    //set fields individually
    await updateDoc(vehicleDocRef, {
      approved: isApproved,
      lastUpdate: serverTimestamp(),
    });
    const updatedVehicleSnapShot = await getDoc(vehicleDocRef);
    const fetchedVehicle = {
      ...updatedVehicleSnapShot.data(),
      createdAt: updatedVehicleSnapShot.data()!.createdAt.toMillis(),
      //lastUpdate: updatedVehicleSnapShot.data()!.lastUpdate.toMillis(), //avoid error on update for myself
      lastUpdate: new Date().getTime(),
    } as PHVehicleType;
    //user data
    const userDocRef = doc(firestore, "users", userId);
    const approvedDriverDocRef = doc(firestore, "approvedDrivers", userId);
    const approvedDriverDoc = await getDoc(approvedDriverDocRef);
    //if vehicle selected and the Driver is approved, but not readyToDrive
    //set readyToDrive and add to approvedDrivers
    if (isApproved && fetchedVehicle.selected) {
      const userData = (await getDoc(userDocRef)).data() as CurrentUserType;
      if (
        userData.approvedDriver &&
        (!userData.readyToDrive || !approvedDriverDoc.exists())
      ) {
        await setDoc(approvedDriverDocRef, {
          driverNumber: userData.driverNumber,
          name: userData.name,
          phoneNumber: userData.phoneNumber,
          licensingAuthority: userData.driversData?.licensingAuthority,
          badgeNumber: userData.driversData?.badgeNumber,
          availableVehicleTypes: fetchedVehicle.vehicleTypes,
          selectedVehicleTypes: fetchedVehicle.vehicleTypes,
          pausedRequests: false,
          petFriendly: userData.driversData?.petFriendly,
          PHvehicle: {
            numberPlate: fetchedVehicle.numberPlate,
            licencePlate: fetchedVehicle.licencePlate,
            make: fetchedVehicle.make,
            model: fetchedVehicle.model,
            color: fetchedVehicle.color,
          },
        });
        await updateDoc(userDocRef, {
          readyToDrive: true,
          lastUpdate: serverTimestamp(),
        });
      }
      //and if Driver is readyToDrive and selected vehicle disabled, disable driver
    } else if (!isApproved && fetchedVehicle.selected) {
      const userData = (await getDoc(userDocRef)).data() as CurrentUserType;
      if (userData.readyToDrive) {
        await updateDoc(userDocRef, {
          readyToDrive: false,
          lastUpdate: serverTimestamp(),
        });
      }
      if (approvedDriverDoc.exists()) {
        await deleteDoc(approvedDriverDocRef);
      }
    }
    return fetchedVehicle;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error changing vehicle status!");
    }
  }
};
//Grant or remove Admin Rights
export const addRemoveAdminRights = async (userId: string, grant: boolean) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const changingRightsUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/addRemoveAdminRights"; //local tesing
      const changingRightsUri =
        "https://addremoveadminrights-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        changingRightsUri,
        { userId, grant },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error changing admin rights.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

// ---===BOOKINGS===---
//Change Booking Price
export const changeBookingPrice = async (
  bookingId: string,
  newPrice: string
) => {
  //booking data
  const bookingDocRef = doc(firestore, "bookings", bookingId);
  try {
    //set fields individually
    await updateDoc(bookingDocRef, {
      quotedPrice: newPrice,
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("Error changing ooking price!");
    }
  }
};
//assign a Driver to the job
export const assignDriver = async (jobId: string, driverNumber: string) => {
  //booking data
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const assignJobUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/assignJobToDriver"; //local tesing
      const assignJobUri = "https://assignjobtodriver-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        assignJobUri,
        { jobId, driverNumber },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data.message;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Endpoint error assigning Driver to a job.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};
//admin cancels the job
export const cancelJobByAdmin = async (
  jobId: string,
  reasonOfReject: string
) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const cancelingUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/cancelJobByAdmin"; //local tesing
      const cancelingUri = "https://canceljobbyadmin-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        cancelingUri,
        { jobId, reasonOfReject },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error cancelling job.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

// ---===CONTACTs===---
//Process contact form
export type ContactFormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
  captchaToken: string;
};
export const sendContactFormToEmails = async (contact: ContactFormData) => {
  //send emails to admins
  const { name, email, phone, message, captchaToken } = contact;
  try {
    // const contactFormUri =
    //   "http://localhost:5001/rufus-taxi/europe-west2/processContactForm"; //local tesing
    const contactFormUri = "https://processcontactform-tvhiteyzsa-nw.a.run.app";
    const { data } = await axios.post(contactFormUri, {
      name,
      email,
      phone,
      message,
      captchaToken,
    });
    return data;
  } catch (error) {
    if (error instanceof AxiosError && !!error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Error sending the message.");
    }
  }
};
