import { FC } from "react";
import { Link, Navigate } from "react-router-dom";

import { BookingType } from "../../redux/bookings/bookings.types";
import { GreenHeader, GreenP } from "../../global.styles";
import { SpacerVertical } from "../UI/spacers/spacers.component";
import Addresses from "./addresses.component";
import { defineVehicleType } from "../../util-functions";

type QuoteDetailsProps = {
  quote: BookingType;
};

const QuoteDetails: FC<QuoteDetailsProps> = ({ quote }) => {
  const selectedVehicleType = defineVehicleType(quote.vehicleType);
  if (!selectedVehicleType) {
    return <Navigate to="/bookings/quote-form" replace />;
  }
  return (
    <>
      <GreenHeader>Here is Your quote:</GreenHeader>
      <GreenP>
        You have requested a <b>{selectedVehicleType}</b>,<br /> on{" "}
        <b>{quote.date}</b> at <b>{quote.time}</b>.
      </GreenP>
      <Addresses booking={quote} />
      {quote.meetAndGreet && (
        <SpacerVertical $paddingRem={1}>
          <GreenP>
            You have requested <b>Meet & Greet</b> option, so we have added{" "}
            <b>£10</b> to your fare.
          </GreenP>
        </SpacerVertical>
      )}
      {quote.hasPet && (
        <SpacerVertical $paddingRem={1}>
          <GreenP>
            You also have requested <b>Pet</b> friendly car, so we have added{" "}
            <b>£8</b> to your fare.
          </GreenP>
        </SpacerVertical>
      )}
      <SpacerVertical $paddingRem={1}>
        <GreenP>
          Total distance of <b>{quote.distanceInMiles}</b> miles. If traffic is
          ok, it should take about <b>{quote.durationTime}</b>.
        </GreenP>
      </SpacerVertical>
      <GreenHeader>Quoted Price: £{quote.quotedPrice}*</GreenHeader>
      <GreenP>
        * Amount to pay to the Driver. Any parking and/or toll fees and/or
        waitng time charges to be added. Please see{" "}
        <Link to="/terms">Terms and Conditions</Link>
      </GreenP>
    </>
  );
};

export default QuoteDetails;
