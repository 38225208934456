import { ChangeEvent, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaDog, FaEdit, FaExclamationTriangle } from "react-icons/fa";

import {
  AmberHeader,
  BackButtonContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  RedP,
  SpreadButtonContainer,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import { isBookingType } from "../../../redux/bookings/bookings.types";
import Button from "../../UI/button/button.component";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import { acceptJobRequest, cancelJobRequest } from "../../../firebase/user";
import { colors } from "../../../style-variables";
import FormInput from "../../UI/form-input/form-input.component";
import { MdEmojiPeople } from "react-icons/md";
import InnerCard from "../../UI/inner-card/inner-card.component";
import AssignDriver from "../../admin/forms/assign-driver.component";
import { makeJobPublic } from "../../../firebase/bookings";
import AddBookingNote from "../../admin/forms/add-booking-note.component";

const JobOfferDetails = () => {
  const navigate = useNavigate();

  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const { jobsOffersLoading, jobsOffersError, jobOffers } = useSelector(
    (state: RootState) => state.jobOffers
  );

  const [acceptJobLoading, setAcceptJobLoading] = useState(false);
  const [acceptJobError, setAcceptJobError] = useState("");
  const [jobAccepted, setJobAccepted] = useState(false);
  const [jobCancelled, setJobCancelled] = useState(false);
  const [showAcceptForm, setShowAcceptForm] = useState(false);
  const [showAssignDriverForm, setShowAssignDriverForm] = useState(false);
  const [showMakePublicForm, setShowMakePublicForm] = useState(false);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [reasonOfReject, setReasonOfReject] = useState("");
  const [jobRedirected, setJobRedirected] = useState(false);
  const [loadingPublic, setLoadingPublic] = useState(false);
  const [makePublicError, setMakePublicError] = useState("");
  const [showAddNoteForm, setShowAddNoteForm] = useState(false);

  //get state that was passed to this route
  const { state } = useLocation();

  if (jobCancelled) {
    return (
      <Card>
        <GreenHeader>Job cancelled</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => navigate("/drivers/my-job-offers")}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  if (jobAccepted) {
    return (
      <Card>
        <GreenHeader>Job successfully accepted!</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => navigate("/drivers/accepted-jobs")}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  if (jobRedirected) {
    return (
      <Card>
        <GreenHeader>Job successfully redirected!</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => navigate("/drivers/accepted-jobs")}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  if (!state || !approvedDriver || !jobOffers) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers/driver-panel")}>
            Try Again
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  const jobId = state.jobId;
  const job = jobOffers.find((job) => job.id === jobId);

  if (!jobAccepted && !isBookingType(job)) {
    return (
      <Card>
        <GreenHeader>This job is no longer available...</GreenHeader>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers/driver-panel")}>Ok</Button>
        </BackButtonContainer>
      </Card>
    );
  }

  const reasonChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setReasonOfReject(event.target.value);

  const rejectActionHandler = async () => {
    if (!reasonOfReject || reasonOfReject === "") {
      setAcceptJobError(
        "Please share your reason for not accepting this booking to help us improve."
      );
      return;
    }
    if (jobId) {
      setAcceptJobLoading(true);
      try {
        const data = await cancelJobRequest(jobId, reasonOfReject);
        if (data && data.message && data.message === "Job cancelled!") {
          setJobCancelled(true);
        }
      } catch (error) {
        if (error instanceof Error) {
          setAcceptJobError(error.message);
        } else {
          setAcceptJobError(
            "Problem cancelling this job... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setAcceptJobLoading(false);
    } else {
      navigate("/drivers/my-job-offers");
    }
  };

  const acceptJobHandler = () => {
    if (jobId) {
      setAcceptJobLoading(true);
      acceptJobRequest(jobId)
        .then((accepted) => {
          setJobAccepted(accepted);
          setAcceptJobLoading(false);
        })
        .catch((error) => {
          if (error instanceof Error) {
            setAcceptJobError(error.message);
          } else {
            setAcceptJobError(
              "Problem accepting this job... Please make sure all parameters match. If problem still exists, please let us know"
            );
          }
          setAcceptJobLoading(false);
        });
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  const makePublicHandler = async () => {
    setLoadingPublic(true);
    try {
      await makeJobPublic(jobId);
      setMakePublicError("");
      setJobRedirected(true);
    } catch (error) {
      if (error instanceof Error) {
        setMakePublicError(error.message);
      } else {
        setMakePublicError("Problem making this job public...");
      }
    } finally {
      setLoadingPublic(false);
    }
  };

  return (
    <Card>
      {(approvedDriverLoading || acceptJobLoading || jobsOffersLoading) && (
        <Spinner />
      )}
      {approvedDriverError && !approvedDriverLoading && (
        <RedHeader>{approvedDriverError}</RedHeader>
      )}
      {jobsOffersError && !jobsOffersLoading && (
        <RedHeader>{jobsOffersError}</RedHeader>
      )}
      {!acceptJobLoading && !!job && (
        <>
          <BackButtonContainer>
            <ClickableArea onClick={() => navigate(-1)}>
              <h3>Go Back</h3>
            </ClickableArea>
          </BackButtonContainer>
          <GreenHeader>
            Personal request for
            <br />
            {job.date}
            <br />
            at {job.time}
          </GreenHeader>
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={job} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{job.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{job.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(job.vehicleType)}</p>
          {job.meetAndGreet && (
            <>
              <br />
              <GreenP>
                <MdEmojiPeople size={24} /> Meet & Greet requested.
              </GreenP>
            </>
          )}
          {job.hasPet && (
            <>
              <br />
              <GreenP>
                <FaDog /> Booking includes pet.
              </GreenP>
            </>
          )}
          {job.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{job.driverNote}</p>
            </>
          )}
          {job.additionalNote && (
            <>
              <br />
              <GreenP>Additional note:</GreenP>
              <p>{job.additionalNote}</p>
            </>
          )}
          <br />
          <ClickableArea onClick={() => setShowAddNoteForm(!showAddNoteForm)}>
            <GreenP>
              {job.additionalNote ? "Edit" : "Add"} Note <FaEdit />
            </GreenP>
          </ClickableArea>
          {showAddNoteForm && jobId && (
            <AddBookingNote
              bookingId={jobId}
              currentNote={job.additionalNote || ""}
              onNoteAdded={() => setShowAddNoteForm(false)}
            />
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{job.quotedPrice}*</GreenHeader>
          <GreenP>
            * Amount to pay to the Driver. Any parking and/or toll fees and/or
            waitng time charges to be added. Please see{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </GreenP>
          <TextDivider>
            <GreenP> ACTIONS </GreenP>
          </TextDivider>
          {acceptJobError && !acceptJobLoading && (
            <RedHeader>{acceptJobError}</RedHeader>
          )}
          <InnerCard onClick={() => setShowAcceptForm(!showAcceptForm)}>
            <h3>Accept Job</h3>
          </InnerCard>
          {showAcceptForm && (
            <>
              <FaExclamationTriangle size={24} color={colors.primary} />
              <GreenP>
                <b>Important Reminder:</b>
              </GreenP>
              <GreenP>
                Before accepting this job, please check Your other bookings to
                ensure there are no scheduling conflicts. Let’s keep all jobs on
                time! Thank you!
              </GreenP>
              <SpreadButtonContainer>
                <Button
                  buttonType="inverted"
                  onClick={() => {
                    setShowAcceptForm(false);
                    setAcceptJobError("");
                  }}
                >
                  Go Back
                </Button>
                <Button onClick={acceptJobHandler}>Accept The Job</Button>
              </SpreadButtonContainer>
            </>
          )}
          <InnerCard
            onClick={() => setShowAssignDriverForm(!showAssignDriverForm)}
          >
            <h3>Send it to another Driver</h3>
          </InnerCard>
          {showAssignDriverForm && (
            <>
              <FaExclamationTriangle size={24} color={colors.primary} />
              <GreenP>
                Please make sure that the Driver You are sending this job to, is
                informed and is willing to do it!
              </GreenP>
              <AssignDriver
                bookingId={job.id!}
                onDriverAssigned={() => {
                  setShowAssignDriverForm(false);
                  setJobRedirected(true);
                }}
                onCancel={() => setShowAssignDriverForm(false)}
              />
            </>
          )}
          <InnerCard onClick={() => setShowMakePublicForm(!showMakePublicForm)}>
            <h3>Make it Public</h3>
          </InnerCard>
          {showMakePublicForm && (
            <>
              <GreenP>
                <strong>Note:</strong> When You make this job Public, it will no
                longer be treated as a Personal Request to You and will instead
                become available to all our approved Drivers.
              </GreenP>
              {makePublicError && <RedP>{makePublicError}</RedP>}
              {loadingPublic ? (
                <Spinner />
              ) : (
                <SpreadButtonContainer>
                  <Button
                    buttonType="inverted"
                    onClick={() => {
                      setShowMakePublicForm(false);
                      setMakePublicError("");
                    }}
                  >
                    Go Back
                  </Button>
                  <Button onClick={makePublicHandler}>Make it Public</Button>
                </SpreadButtonContainer>
              )}
            </>
          )}
          <InnerCard onClick={() => setShowCancelForm(!showCancelForm)}>
            <h3>Cancel Job</h3>
          </InnerCard>
          {showCancelForm && (
            <>
              <AmberHeader>Cancel this job?</AmberHeader>
              <FaExclamationTriangle size={24} color={colors.primary} />
              <GreenP>
                Please ensure You have a valid reason to cancel this job, rather
                than passing it to another Driver or making it publicly
                available to all approved Drivers.
              </GreenP>
              <FormInput
                label="Please type the reason..."
                onChange={reasonChangeHandler}
                id="reasonOfReject"
                name="reasonOfReject"
                type="text"
                value={reasonOfReject}
                required
              />
              <SpreadButtonContainer>
                <Button
                  buttonType="inverted"
                  onClick={() => {
                    setShowCancelForm(false);
                    setAcceptJobError("");
                  }}
                >
                  Go Back
                </Button>
                <Button onClick={rejectActionHandler}>Cancel Job</Button>
              </SpreadButtonContainer>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default JobOfferDetails;
