import { ChangeEvent, FC, useState } from "react";
import {
  ButtonColumnContainer,
  RedP,
  SpreadButtonContainer,
} from "../../../global.styles";
import FormInput from "../../UI/form-input/form-input.component";
import Button from "../../UI/button/button.component";
import { addBookingNote } from "../../../firebase/bookings";
import Spinner from "../../UI/spinner/spinner.component";

type AddBookingNoteProps = {
  bookingId: string;
  currentNote: string;
  onNoteAdded: () => void;
};

const AddBookingNote: FC<AddBookingNoteProps> = ({
  bookingId,
  currentNote,
  onNoteAdded,
}) => {
  const [note, setNote] = useState(currentNote);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const noteChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const submitHandler = async () => {
    setLoading(true);
    try {
      await addBookingNote(bookingId, note);
      onNoteAdded();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("Error adding note...");
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spinner />;

  return (
    <>
      <ButtonColumnContainer>
        {error && <RedP>{error}</RedP>}
        <FormInput
          label={note ? "" : "Add Note Here ..."}
          value={note}
          onChange={noteChangeHandler}
          required
        />
      </ButtonColumnContainer>
      <SpreadButtonContainer>
        <Button buttonType="inverted" type="button" onClick={onNoteAdded}>
          Cancel
        </Button>
        <Button type="submit" onClick={submitHandler}>
          Save
        </Button>
      </SpreadButtonContainer>
    </>
  );
};

export default AddBookingNote;
